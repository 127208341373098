declare module 'o365.pwa.declaration.shared.dexie.objectStores.Index.d.ts' {
    export class Index {
        static objectStoreDexieSchema: string;

        id: string;
        appId: string;
        databaseId: string;
        objectStoreId: string;
        keyPath: string | Array<string> | null;
        isPrimaryKey: boolean;
        isUnique: boolean;
        isMultiEntry: boolean;
        isAutoIncrement: boolean;

        constructor(
            id: string,
            appId: string,
            databaseId: string,
            objectStoreId: string,
            keyPath: string | Array<string> | null,
            isPrimaryKey?: boolean,
            isUnique?: boolean,
            isMultiEntry?: boolean,
            isAutoIncrement?: boolean
        );

        readonly schema: string;

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<Index | null>;
    }
}

export {};
